/*** Sprites ***/
.icon {
	margin: 0px auto;
}
.icon-sheet, .icon-pile, .icon-stork, .icon-speech,
.icon-post, .icon-euro, .icon-see, .icon-horn, .icon-horse,
.icon-person {
	background-image: url('../../imgs/sprites.png');
	background-repeat: no-repeat;
	width: 100px;
	height: 100px;
}
.icon-person {
	display: inline-block;
	background-position: -200px -200px;
	&:hover {
		background-position: -300px -200px;
	}
}
.icon-post {
	display: inline-block;
	background-position: -100px -100px;
	&:hover {
		background-position: -100px -400px;
	}
}
.icon-horse {
	background-position: -200px -100px;
	&:hover {
		background-position: -200px -400px;
	}
}
.icon-pile {
	background-position: -300px -100px;
	&:hover {
		background-position: -300px -400px;
	}
}
.icon-sheet {
	background-position: 0 -200px;
	&:hover {
		background-position: -100px -200px;
	}
}
.icon-stork {
	background-position: -100px 0;
	&:hover {
		background-position:  -100px -300px;
	}
}
.icon-see {
	background-position: 0 -100px;
	&:hover {
		background-position:  0 -400px;
	}
}
.icon-horn {
	background-position: -301px 0;
	&:hover {
		background-position:  -301px -300px;
	}
}
