@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800,100);

@import "mixins";

/*** General styles ***/

@-webkit-viewport {width: device-width;}
@-moz-viewport {width: device-width;}
@-ms-viewport {width: device-width;}
@-o-viewport {width: device-width;}
@viewport {width: device-width;}

$defFontFamily: 'Raleway', sans-serif;
$defWeight: 400;
$heavyWeight: 800;
$lightWeight: 100;

$PrimaryColor: #0f4232; 	// Racing Green 0f4232
$SndColor: #FEF8CF; 			// Crème kleur FEF8CF
$ThirdColor: #032b1f;			// Donker Groen 001710
$FourthColor: #cad7e5; 		// Licht blauw
$defKleur: rgb(51,51,51);

$defFont: 1.6rem;
$defPadding: 25px;

$defUrl: 'http://localhost:8888/baps_wp';

html {
	position: relative;
	height: 100%;
	font-size: 62.5%;
}
body {
	position: relative;
	height: 100%;
	width: 100%;
	background-color: $SndColor;
	font-family: $defFontFamily;
	font-size: 16px;
	font-size: $defFont;
	padding-top: 60px;
}
h1, h2, h3 {
	font-weight: $heavyWeight;
}
a {
	color: $FourthColor;
	&:hover {
		color: $FourthColor;
		text-decoration: underline;
	}
}
.strong {
	font-weight: $heavyWeight;
}
.center {
	text-align: center;
}
.border-bottom {
	padding-bottom: $defPadding;
	margin-bottom: $defPadding;
	border-bottom: 3px solid $SndColor;
}
/*** Navbar ***/
.navbar-baps {
	background-color: $PrimaryColor;
	& a {
		color: $SndColor;
		font-weight: $defWeight;
	}
	border-bottom: 3px solid $SndColor !important;
}
.navbar-brand {
	padding: 4px 15px !important;
}
.navbar-brand img {
	max-height: 50px;
}
.navbar-header i {
	color: $SndColor !important;
	font-size: 1.6rem;
}
.nav>li>a:hover,
.nav>li>a:focus {
	background-color: $PrimaryColor !important;
}
.nav-ext {
	transform: translate(0, 200vh);
	display: block;
	background-color: $ThirdColor;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
	color: #fff;
	width: 100%;
	overflow-y: scroll;
	padding: 80px 40px;
	-webkit-transition: transform .5s ease-out;
	transition: transform .5s ease-out;
}
.close {
	transform: translate(0, 200vh);
	position: fixed;
	right: 20px;
	top: 100px;
	z-index: 3;
	-webkit-transition: transform .5s ease-out;
	transition: transform .5s ease-out;
}
.navActive {
	transform: translate(0);
	-webkit-transition: transform .5s ease-out;
	transition: transform .5s ease-out;
}
a#nav-close {
	top: 100px !important;
	right: 50px !important;
	color: #fff !important;
	& i {
		font-size: $defFont * 2;
		font-weight: lighter;
		-webkit-transition: all .8s ease-in-out;
  		transition: all .8s ease-in-out;
		&:hover {
			text-decoration: none;
			-webkit-transform: rotate(360deg);
  			transform: rotate(360deg);
  			font-size: $defFont * 3;
		}
	}
}

/*** Nav extended ***/
.nav-ext li {
	list-style-type: none;
	&.page_item_has_children > a,
	&.page_item a:not(li.page_item a) {
		font-size: 2.5rem !important;
		color: #fff;
	}
}

.nav-ext ul.children {
	padding-left: $defPadding !important;
}

/*** Jumbo ***/
.jumbotron {
	background-color: $SndColor;
	background-image:
		linear-gradient(rgba(254, 248, 207, 0.5), rgba(254, 248, 207, 0.5)),
		url(#{$defUrl}/wp-content/uploads/2016/01/A2B8346.jpg);
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
	&.fullHeight {
		background-image:
		linear-gradient(rgba(254, 248, 207, 0.5), rgba(254, 248, 207, 0.5)),
		url(#{$defUrl}/wp-content/uploads/2016/01/baps-home-portrait.jpg);
		min-height: 100%;
		background-size: 100% auto;
		background-position: top;
	}
	//padding-top: $defPadding * 2;
	margin-bottom: 0 !important;
	& p {
		font-size: 1.6rem;
		font-weight: $heavyWeight;
		& a {
			display: inline-block;
			margin-top: 10px;
		}
	}
	.news {
		background-color: $ThirdColor;
		opacity: 0.9;
		color: $SndColor;
		padding-top: $defPadding / 2;
		padding-bottom: $defPadding;
		& p {
			font-weight: $defWeight;
		}
	}
}
	/*** Buttons ***/
.btn, input {
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0 !important;
}
.btn-baps {
	background-color: $ThirdColor;
	color: $SndColor;
	border: none;
	border-bottom: 3px solid $SndColor;
	&:hover {
		background-color: $PrimaryColor;
		color: $SndColor;
	}
}
.btn-light {
	display: inline-block;
	background: none;
	border: 1px solid $ThirdColor;
	color: $ThirdColor;
	&:hover {
		background-color: $PrimaryColor;
		color: $SndColor;
	}
}

/*** Slider ***/

.slickSlider {
	margin-top: $defPadding * 2;
	& .surround {
		// border: 1px solid $PrimaryColor;
		vertical-align: middle;
		text-align:center;
		padding: 0 auto;
		margin-left: 50px;
	}
	& img {
		height: 150px !important;
	}
}

.slick-slide {
	/*width: 80%;
	height: 100px;
	margin-left: 5px;
	padding: 50px auto;*/
	& img {
		-webkit-filter: sepia(1);
		filter: sepia(1);
		-webkit-transition: all 0.4s ease;
		transition: all 0.4s ease;
		&:hover {
			-webkit-filter: sepia(0);
			filter: sepia(0);
		}
	}
}

/*** Stramienpage ***/
.stramien {
	padding-bottom: $defPadding * 4;
}
ul {
	padding-left: 0 !important;
	& li {
		list-style-type: none;
		.stramien & a {
			color: $defKleur;
		}
	}
}

.footer {
	position: fixed;
	background-color: $PrimaryColor;
	color: $SndColor;
	& p {
		font-size: 0.8em;
	}
	left: 0;
	bottom: 0;
	width: 100%;
	height: 50px !important;
	margin-top: -50px;
	padding-top: 10px;
}

.btn-icon {
	color: $defKleur;
	font-size: 1.5em;
}

/***  480px breakpoint  ***/
@media only screen and (min-width: 30em) {
   }

/***  600px breakpoint  ***/
@media only screen and (min-width: 38em) {
 }

/***  768px breakpoint  ***/
@media only screen and (min-width: 48em) and (max-width: 62em) {
 .navbar-collapse {
       display: none !important;
   }

}

/***  992px breakpoint  ***/
@media only screen and (min-width: 62em) {
	body {
		padding-top: 88px;
	}
	.navbar-baps {
		min-height: 75px;
		margin-bottom: 0 !important;
		& li a {
			font-size: 1.5rem;
			padding-top: 2.5rem;
			&:hover {
				background: $PrimaryColor !important;
				color: $SndColor !important;
				border-bottom: 6px solid $SndColor !important;
			}
		}

		& .navbar-right li {
			background-color: $SndColor;
			margin-left: 5px !important;
			border-bottom: 3px solid $PrimaryColor;
			border-top: 3px solid $PrimaryColor;
			& a {
				color: $PrimaryColor !important;
				&:hover {
					background-color: $ThirdColor !important;
					color: $SndColor !important;
					border: none !important;
				}
			}
		}
	}
	.sidebar-nav .navbar .navbar-collapse {
		padding: 0;
		max-height: none;
	  }
	  .sidebar-nav .navbar ul {
		float: none;
	  }
	  .sidebar-nav .navbar ul:not {
		display: block;
	  }
	  .sidebar-nav .navbar li {
		float: none;
		display: block;
	  }
	  .sidebar-nav .navbar li a {
		padding-top: 12px;
		padding-bottom: 12px;
	  }
	.jumbotron {
		margin-top: -40px !important;
		padding-top: $defPadding * 3;
		&.fullHeight {
		background-image:
			linear-gradient(rgba(254, 248, 207, 0.6), rgba(254, 248, 207, 0.6)),
			url(#{$defUrl}/wp-content/uploads/2016/01/A2B8346.jpg);
		background-size: 100% 100%;
		background-position: center center;
		}
		& p {
			font-size: 1.8rem;
			font-weight: $heavyWeight;
			& a {
				display: inline-block;
				margin-top: 10px;
			}
		}
		min-height: 40vh;
		& .news {
			padding-left: $defPadding * 1.5;
			padding-right: $defPadding * 1.5;
		}
	}

	.navbar-brand {
		padding: 15px 15px !important;
	}
}

/***  1382px breakpoint  ***/
@media only screen and (min-width: 86em) {
	.jumbotron.fullHeight {
		background-size: 100% auto !important;
	}
	.footer {
		position: fixed;
	}
}

@import "dashboard";
@import "sprites";
@import "studbook";
@import "imports/leden_dashboard";
