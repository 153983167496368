hr {
	//border: 0;
	//border-top: 1px solid $PrimaryColor;
}
.message-row {
		padding: 10px;
}

.panel-baps-users {
		border: 1px solid $PrimaryColor !important;

		.panel-heading {
				background-color: $PrimaryColor !important;
				color: $SndColor;
				text-align: center;
		}
		.panel-body {
				background: $SndColor;
				&, & a {
					color: $PrimaryColor;
				}
		}
}

.table-baps-users {
		.btn-new {
				background-color: $PrimaryColor;
				color: $SndColor;
		}
}

.nav-tabs {
	border-bottom-color: $PrimaryColor;
	.active a {
		border: 1px solid $PrimaryColor !important;
		border-bottom-color: transparent !important;
		background-color: $PrimaryColor !important;
		color: $SndColor !important;
	}
	a {
		color: $PrimaryColor;

		&:hover {
			color: $SndColor;
		}
	}
}

.pagination {
	.span {
		border-color: $PrimaryColor;
		background-color: $SndColor;
	}
	.active span {
		border-color: $PrimaryColor;
		background-color: $PrimaryColor;
		color: $SndColor;
	}
}