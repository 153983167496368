@mixin flex() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flow($dir, $wrap) {
    -webkit-flex-flow: $dir $wrap;
    flex-flow: $dir $wrap;
}

@mixin justify($value) {
    -webkit-justify-content: $value;
    justify-content: $value;
}

@mixin flex-item() {
    flex: 0 1 auto;
}

@mixin column-layout($nr, $gap) {
    -moz-column-count: $nr;
    -webkit-column-count: $nr;
    column-count: $nr;
    -moz-column-gap: $gap;
    -webkit-column-gap: $gap;
    column-gap: $gap;
}