.flex {
    @include flex();
    @include flow(row, wrap);
    @include justify(space-evenly);
    height: 250px;
}
.flex-item {
    @include flex-item();
    flex-grow: 1;
}
.studbook {
    &.thumbnail {
        position: relative;
        background-color: $SndColor;
        border: 1px solid $PrimaryColor;
        color: $PrimaryColor;
        -webkit-border-radius: 0px;
        -ms-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
    }
    & .form-group,
    & .form-group input {
        width: 100% !important;
    }
    & select {
        border-radius: 0px !important;
        -webkit-border-radius: 0px !important;
        -moz-border-radius: 0px !important;
    }
    & td a {
        color: $PrimaryColor !important;
        text-decoration: underline;
    }
    & select#typeOpzoeking {
        background: none !important;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px;
        -ms-border-radius: 0px;
        border-radius: 0px;
    }
}

.btn-snd-light {
    border: 1px solid $PrimaryColor;
    color: $PrimaryColor;
    &:hover {
        background-color: $PrimaryColor;
        color: $SndColor;
    }
}

.tiny {
    font-size: 1.2rem;
    font-weight: lighter;
    font-family: "Helvetica", sans-serif;
    & a {
        color: $FourthColor;
    }
}

.pagination li {
    &.active a {
        background-color: $PrimaryColor;
        border-color: $PrimaryColor !important;
        &:hover {
            background-color: $SndColor;
            color: $PrimaryColor;
            cursor: pointer;
        }
    }
    & a {
        color: $PrimaryColor;
    }
}

.bg-studbook-light {
    background-color: #e5e5e5;
}

.bg-studbook-white {
    background-color: white;
}

.border-baps {
    padding: 15px;
    border: 2px solid $PrimaryColor;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.rotatable {
    border-collapse: collapse;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    tr, td {
        position: relative;
        padding: 10px;
        border: 1px solid $PrimaryColor;
    }
}

.vertical-td {
    text-align: right;
}

.vertical-td-span {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    transform-origin: 0 50%;
    white-space: nowrap;
    display: block;
    position: absolute;
    //bottom: 0;
    left: 50%;
}